<template>
  <div class="about">
    <v-container>
      <v-layout row wrap justify-space-around class="mx-2">
        <v-flex xs12 lg4>
          <br>
          <br>
          <br>
          <v-card flat>
            <v-img  aspect-ratio="1" :src="img" alt=""></v-img>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-around class="mx-2">
        <v-flex xs12 lg4>
          <h3 class="kurdish text-center">بەخێر بێی بۆ پەیجی ماڵەوەی شایەن، بەس لەماڵ نین لە ئیشین ئەم دەقەیە</h3>
        </v-flex>
        
      </v-layout>
      <v-layout row wrap justify-space-around class="mx-2">
        <v-flex xs12 lg4 class="text-center">
          <br>
          <br>
          <br>
          <v-card color="primary" dark class="kurdish text-center elevation-0" to="/download" x-large>
            <v-card-title primary-title>
                نەڕۆی! شایەن دابەزێنە
          <v-spacer></v-spacer>
            <v-icon right >fab fa-app-store</v-icon>
            <v-icon right >fab fa-google-play</v-icon>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default{
  data(){
    return{
      img : require('../assets/bro.png')
    }
  },
  mounted() {
    this.$vuetify.rtl = true
  },
}
</script>
